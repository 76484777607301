import React from 'react'
import { Card, CardContent } from '@/components/Card'
import { StatBlock } from '@/components/StatBlock'
import { Coin } from '@/components/Coin'

interface StatsPopupProps {
  level: number
  rewards: number
  stats: {
    primary: string | number
    secondary: string
    icon?: React.ReactNode
  }[]
}

export default function StatsPopup({ level, rewards, stats }: StatsPopupProps) {
  return (
    <div className="fixed inset-0 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg text-white shadow-xl overflow-hidden">
        <CardContent className="p-8 space-y-6">
          {/* Level and Rewards Row */}
          <div className="flex justify-between items-center text-lg font-bold mb-8">
            <div>Lvl. {level}</div>
            <div className="flex items-center gap-2">
              {rewards.toLocaleString()}
              <Coin className="w-6 h-6" animate={true} />
            </div>
          </div>

          {/* Stats Grid */}
          <div className="flex">
            <StatBlock
              primaryText={stats[0].primary}
              secondaryText={stats[0].secondary}
              rightIcon={stats[0].icon}
              size="sm"
              className="w-full"
            />
          </div>
          <div className="flex">
            <StatBlock
              primaryText={stats[1].primary}
              secondaryText={stats[1].secondary}
              rightIcon={stats[1].icon}
              size="sm"
              className="w-full"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <StatBlock
              primaryText={stats[2].primary}
              secondaryText={stats[2].secondary}
              rightIcon={stats[2].icon}
              size="sm"
            />
            <StatBlock
              primaryText={stats[3].primary}
              secondaryText={stats[3].secondary}
              rightIcon={stats[3].icon}
              size="sm"
            />
          </div>

          {/* Bottom Full Width Stat */}
          {stats[4] && (
            <div className="mt-4">
              <StatBlock
                primaryText={stats[4].primary}
                secondaryText={stats[4].secondary}
                rightIcon={stats[4].icon}
                size="sm"
                className="w-full"
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
