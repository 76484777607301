import { FlagsResponse } from '@/types/APITypes'
import { FlagViewProps } from '@/types/FlagViewTypes'
import { defaultFlags } from './FlagsView'
import { ListTab } from './ListTab'
import { HomeTab } from './HomeTab'

export function trimCid(cid: string): string {
  return cid.substring(cid.length - 11)
}

export function calculateFlagValues(flags: FlagsResponse[]): FlagViewProps {
  if (Array.isArray(flags)) {
    const inReviewFlags = flags.filter(
      (flag) => flag.flag_status === 'in_review'
    )
    const acceptedFlags = flags.filter(
      (flag) => flag.flag_status === 'accepted'
    )
    const rejectedFlags = flags.filter(
      (flag) => flag.flag_status === 'rejected'
    )
    return {
      inreviewFlags: inReviewFlags.length,
      acceptedFlags: acceptedFlags.length,
      rejectedFlags: rejectedFlags.length,
      rewards:
        acceptedFlags.length *
        parseInt(process.env.REACT_APP_FLAG_REWARD ?? '100'),
      penalties:
        rejectedFlags.length *
        parseInt(process.env.REACT_APP_FLAG_PENALTY ?? '0'),
      rank: null,
    }
  }
  return defaultFlags
}
export const renderTab = ({
  tab,
  props,
  flags,
}: {
  tab: FlagTypes
  props: FlagViewProps
  flags: FlagsResponse[] | null | undefined
}) => {
  switch (tab) {
    case FlagTypes.IN_REVIEW:
      return <ListTab tab={FlagTypes.IN_REVIEW} flags={flags} />
    case FlagTypes.ACCEPTED:
      return <ListTab tab={FlagTypes.ACCEPTED} flags={flags} />
    case FlagTypes.REJECTED:
      return <ListTab tab={FlagTypes.REJECTED} flags={flags} />
    default:
      return (
        <HomeTab
          rank={props.rank}
          inreviewFlags={props.inreviewFlags}
          acceptedFlags={props.acceptedFlags}
          rejectedFlags={props.rejectedFlags}
          rewards={props.rewards}
          penalties={props.penalties}
        />
      )
  }
}
export enum FlagTypes {
  HOME = 'home',
  IN_REVIEW = 'in_review',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}
