import { HeaderBarProps } from '@/types/HeaderBarTypes'
import { Coin } from '@/components/Coin'

export default function HeaderBar({
  currentScore,
  dailyStreak,
  currentLevel,
}: HeaderBarProps) {
  return (
    <div className="bg-gray-900 p-4 border-b border-gray-800">
      <div className="flex justify-between items-center max-w-lg mx-auto w-full">
        Lvl. {currentLevel}
        <div className="flex items-center gap-1 text-sm text-gray-600">
          <Coin /> {currentScore.toLocaleString()}
        </div>
        <div className="flex items-center gap-1 text-sm text-gray-600">
          <span className="text-yellow-500">🗓️</span> {dailyStreak}
        </div>
      </div>
    </div>
  )
}
