import { Card, CardContent } from '@/components/Card'
import { Button } from '@/components/Button'
import { Coin } from '../components/Coin'
import { StatBlock } from '../components/StatBlock'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useState } from 'react'

interface ReferralsInfoViewProps {
  telegramId?: string
  action?: () => void
  referrals?: number
  rewards?: number
}

function buildReferralLink(telegramId: string) {
  return process.env.REACT_APP_REFERRAL_LINK
    ? process.env.REACT_APP_REFERRAL_LINK + Number(telegramId)
    : 'MISSING LINK BRO'
}

export default function ReferralsInfoView({
  telegramId,
  action,
  referrals = 0,
  rewards = 0,
}: ReferralsInfoViewProps) {
  const [showButton, setShowButton] = useState(true)

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg text-white shadow-xl overflow-hidden">
        <CardContent className="p-8 space-y-6 text-center">
          <h2 className="text-md font-bold tracking-wider flex items-center justify-center gap-2 text-yellow-500">
            Invite and earn more!
          </h2>

          <p className="text-md tracking-wider flex items-center gap-1">
            Earn 200 <Coin animate={false} /> for every invite
          </p>

          <p className="text-xl tracking-wider">
            The more you invite, the higher the reward{' '}
          </p>
          {telegramId && (
            <CopyToClipboard text={buildReferralLink(telegramId ?? '-1')}>
              {showButton ? (
                <Button
                  variant="retro"
                  onClick={() => {
                    setShowButton(false)
                    setTimeout(() => {
                      setShowButton(true)
                    }, 1000)
                    action?.()
                  }}
                  className="font-press-start"
                >
                  COPY LINK
                </Button>
              ) : (
                <Button variant="yes" className="font-press-start" disabled>
                  COPIED!
                </Button>
              )}
            </CopyToClipboard>
          )}
          <p className="text-sm text-yellow-500 tracking-wider">
            Your Referrals
          </p>
          <div className="flex items-center justify-center gap-2">
            <StatBlock
              size="sm"
              className="w-full"
              variant="split"
              leftSecondary={'# Referrals'}
              rightSecondary={'Your Rewards'}
              leftPrimary={referrals}
              rightPrimary={rewards}
              rightIcon={<Coin />}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
