import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { RefObject } from 'react'

const buttonVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'font-mono',
    'font-bold',
    'uppercase',
    'rounded-xl',
    'transition-all',
    'duration-100',
    'disabled:opacity-50',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
  ].join(' '),
  {
    variants: {
      variant: {
        default: [
          'bg-blue-500',
          'shadow-[0_6px_0_0_rgba(29,78,216,0.95)]',
          'active:translate-y-1',
          'active:shadow-[0_2px_0_0_rgba(29,78,216,0.95)]',
          'relative',
          'after:absolute',
          'after:inset-0',
          'after:rounded-xl',
          'after:opacity-0',
          'after:transition-opacity',
          'after:duration-100',
          'after:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.1)]',
          'hover:after:opacity-100',
        ].join(' '),
        secondary: [
          'bg-gray-200',
          'text-black',
          'shadow-[0_6px_0_0_rgba(0,0,0,0.50)]',
          'active:translate-y-1',
          'active:shadow-[0_2px_0_0_rgba(0,0,0,0.75)]',
          'relative',
          'after:absolute',
          'after:inset-0',
          'after:rounded-xl',
          'after:opacity-0',
          'after:transition-opacity',
          'after:duration-100',
          'after:shadow-[inset_0_0_0_2px_rgba(0,0,0,0.1)]',
          'hover:after:opacity-100',
        ].join(' '),
        retro: [
          'bg-orange-500',
          'shadow-[0_6px_0_0_rgba(234,88,12,0.95)]',
          'active:translate-y-1',
          'active:shadow-[0_2px_0_0_rgba(234,88,12,0.95)]',
          'relative',
          'after:absolute',
          'after:inset-0',
          'after:rounded-xl',
          'after:opacity-0',
          'after:transition-opacity',
          'after:duration-100',
          'after:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.1)]',
          'hover:after:opacity-100',
        ].join(' '),
        yes: [
          'bg-green-500',
          'shadow-[0_6px_0_0_rgba(21,128,61,0.95)]',
          'active:translate-y-1',
          'active:shadow-[0_2px_0_0_rgba(21,128,61,0.95)]',
          'relative',
          'after:absolute',
          'after:inset-0',
          'after:rounded-xl',
          'after:opacity-0',
          'after:transition-opacity',
          'after:duration-100',
          'after:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.1)]',
          'hover:after:opacity-100',
        ].join(' '),
        no: [
          'bg-red-500',
          'shadow-[0_6px_0_0_rgba(185,28,28,0.95)]',
          'active:translate-y-1',
          'active:shadow-[0_2px_0_0_rgba(185,28,28,0.95)]',
          'relative',
          'after:absolute',
          'after:inset-0',
          'after:rounded-xl',
          'after:opacity-0',
          'after:transition-opacity',
          'after:duration-100',
          'after:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.1)]',
          'hover:after:opacity-100',
        ].join(' '),
        ghost: ['hover:opacity-70', 'active:scale-95', 'transition-all'].join(
          ' '
        ),
      },
      size: {
        default: 'h-9 px-4 py-2 text-2xl',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8 text-2xl',
        icon: 'h-10 w-10 p-0 text-2xl',
        choice: 'w-full px-8 py-4 text-2xl',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

interface PulseState {
  isPulsing: boolean
  pulseCount: number
  maxPulses: number
  overlayText?: string
  overlayStyle?: {
    textColor?: string
    backgroundColor?: string
  }
}

interface PulseOptions {
  pulses?: number
  text?: string
  textColor?: string
  backgroundColor?: string
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  icon?: React.ReactNode
  onPulseComplete?: () => void
  disabled?: boolean
}

interface ButtonRef extends HTMLButtonElement {
  pulse: (options: PulseOptions) => void
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      icon,
      onPulseComplete,
      disabled,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const [pulseState, setPulseState] = React.useState<PulseState>({
      isPulsing: false,
      pulseCount: 0,
      maxPulses: 0,
      overlayText: undefined,
      overlayStyle: undefined,
    })

    const pulse = React.useCallback(
      ({
        pulses = 3,
        text,
        textColor = 'white',
        backgroundColor = 'rgba(0, 0, 0, 0.5)',
      }: PulseOptions) => {
        setPulseState({
          isPulsing: true,
          pulseCount: 0,
          maxPulses: pulses,
          overlayText: text,
          overlayStyle: {
            textColor,
            backgroundColor,
          },
        })
      },
      []
    )

    React.useEffect(() => {
      if (pulseState.isPulsing) {
        const timer = setTimeout(() => {
          if (pulseState.pulseCount >= pulseState.maxPulses - 1) {
            setPulseState((prev) => ({
              ...prev,
              isPulsing: false,
              overlayText: undefined,
              overlayStyle: undefined,
            }))
            onPulseComplete?.()
          } else {
            setPulseState((prev) => ({
              ...prev,
              pulseCount: prev.pulseCount + 1,
            }))
          }
        }, 600)
        return () => clearTimeout(timer)
      }
    }, [
      pulseState.isPulsing,
      pulseState.pulseCount,
      pulseState.maxPulses,
      onPulseComplete,
    ])

    const content =
      size === 'icon' && icon ? (
        <span className="relative z-10 flex items-center justify-center w-full h-full">
          {!pulseState.isPulsing && icon}
        </span>
      ) : (
        <span className="relative z-10">
          {!pulseState.isPulsing && children}
        </span>
      )

    React.useImperativeHandle(
      ref as React.RefObject<ButtonRef>,
      // @ts-expect-error React.useImperativeHandle return type mismatch
      () => {
        const buttonElement = (ref as React.RefObject<HTMLButtonElement>)
          .current
        return buttonElement ? Object.assign(buttonElement, { pulse }) : null
      },
      [pulse, ref]
    )

    return (
      <div className="relative inline-flex">
        <Comp
          className={cn(
            buttonVariants({ variant, size, className }),
            pulseState.isPulsing && 'animate-button-pulse'
          )}
          disabled={disabled}
          ref={ref}
          {...props}
        >
          {content}
          {pulseState.isPulsing && pulseState.overlayText && (
            <div
              className="absolute inset-0 flex items-center justify-center rounded-xl font-bold animate-fade-in"
              style={{
                backgroundColor: pulseState.overlayStyle?.backgroundColor,
                color: pulseState.overlayStyle?.textColor,
              }}
            >
              {pulseState.overlayText}
            </div>
          )}
        </Comp>
      </div>
    )
  }
)

Button.displayName = 'Button'

export { Button, buttonVariants }
