import { ControlBar } from '@/components/ControlBar'
import { useState } from 'react'
import { useEffect } from 'react'
import ReferralsInfoView from '@/views/ReferralView'
import StatsView from '@/views/StatsView'
import PlayView from '@/views/PlayView/PlayView'
import FlagsView from '@/views/FlagsView/FlagsView'
import { SplashScreenView } from '@/views/SplashScreenView'
import { calculateLevel } from '@/lib/utils'
import { fetchUser } from '@/lib/api/fetchUser'
import { fetchFlags } from '@/lib/api/fetchFlags'
import { FlagsResponse, UserDataResponse } from '@/types/APITypes'
import { ReactNode } from 'react'
import { TelegramProvider, useTelegram } from '@/contexts/TelegramContext'

enum View {
  SplashScreen = 0,
  Referral = 1,
  Play = 2,
  Stats = 3,
  Flags = 4,
}

const getTotalWrongAnswers = (data: UserDataResponse) => {
  return (
    parseInt(data.userData.totalAnswers) -
    parseInt(data.userData.totalRightAnswers)
  )
}

const calculateAccuracy = (data: UserDataResponse) => {
  return (
    (parseInt(data.userData.totalRightAnswers) /
      parseInt(data.userData.totalAnswers)) *
    100
  ).toFixed(0)
}

const renderView = (
  view: number,
  data?: UserDataResponse | null,
  refetchUserData?: () => void,
  currentTotalRightAnswers?: number,
  flags?: FlagsResponse[] | null | undefined,
  refetchFlags?: () => void
): ReactNode => {
  switch (view) {
    case View.SplashScreen:
      return <SplashScreenView />
    case View.Referral:
      return (
        <ReferralsInfoView
          telegramId={data?.userData?.telegramId}
          referrals={parseInt(data?.numberOfReferrals ?? '0')}
          rewards={parseInt(data?.numberOfReferrals ?? '0') * 200}
        />
      )
    case View.Play:
      return (
        <PlayView
          multiplier={data?.userData?.multiplier ?? '1'}
          currentScore={parseInt(data?.userData?.score ?? '0')}
          currentStreak={parseInt(data?.userData?.currentstreak ?? '0')}
          dailyStreak={parseInt(data?.userData?.dailyStreak ?? '0')}
          images={data?.queue ?? []}
          refetchUserData={refetchUserData}
          level={calculateLevel(currentTotalRightAnswers ?? 0)}
        />
      )
    case View.Stats:
      return (
        <StatsView
          level={calculateLevel(currentTotalRightAnswers ?? 0)}
          rewards={parseInt(data?.userData?.score ?? '0')}
          stats={[
            {
              primary: data?.userData
                ? calculateAccuracy(data).toString() + '%'
                : '',
              secondary: 'Accuracy',
            },
            {
              primary: data?.userData?.totalAnswers ?? '0',
              secondary: 'Total Answers',
            },
            {
              primary: data?.userData?.totalRightAnswers ?? '0',
              secondary: 'Right Answers',
            },
            {
              primary: data?.userData
                ? getTotalWrongAnswers(data).toString()
                : '',
              secondary: 'Wrong Answers',
            },
            {
              primary: data?.userData?.rank
                ? `${data?.userData.rank.userrank}/${data?.userData.rank.totalusers}`
                : '-',
              secondary: 'Rank',
            },
          ]}
        />
      )
    case View.Flags:
      return (
        <FlagsView
          flags={flags}
          currentScore={parseInt(data?.userData?.score ?? '0')}
          dailyStreak={parseInt(data?.userData?.dailyStreak ?? '0')}
          currentLevel={calculateLevel(currentTotalRightAnswers ?? 0)}
          refetchFlags={refetchFlags ?? (() => {})}
        />
      )
    default:
      return null
  }
}

function App() {
  const [view, setView] = useState<number | null>(null)
  const [userData, setUserData] = useState<UserDataResponse | null>(null)
  const [currentTotalRightAnswers, setCurrentTotalRightAnswers] = useState(0)
  const [flags, setFlags] = useState<FlagsResponse[]>([])
  const { user, hash, ready } = useTelegram()

  const refetchFlags = () => {
    fetchFlags({
      hash: hash ?? process.env.REACT_APP_TEST_HASH ?? '',
      telegramId: user?.id.toString() ?? process.env.REACT_APP_TEST_ID ?? '',
    }).then((data) => {
      setFlags(data)
    })
  }

  const refetchUserData = () => {
    fetchUser({
      telegramUserName: user?.username ?? 'blankUserTest2UBO14',
      referrerId: -1,
      telegramId: user?.id.toString() ?? process.env.REACT_APP_TEST_ID ?? '',
      hash: hash ?? process.env.REACT_APP_TEST_HASH ?? '',
      numberOfImagesToFetch: parseInt(
        process.env.REACT_APP_NUMBER_OF_IMAGES_TO_FETCH ?? '10'
      ),
    }).then((data) => {
      if (data) {
        setUserData(data)
      }
    })
  }

  useEffect(() => {
    if (!userData && ready) {
      fetchUser({
        telegramUserName: user?.username ?? 'blankUserTest2UBO14',
        referrerId: -1,
        telegramId: user?.id.toString() ?? process.env.REACT_APP_TEST_ID ?? '',
        hash: hash ?? process.env.REACT_APP_TEST_HASH ?? '',
        numberOfImagesToFetch: parseInt(
          process.env.REACT_APP_NUMBER_OF_IMAGES_TO_FETCH ?? '10'
        ),
      }).then((data) => {
        if (data) {
          setUserData(data)
          setCurrentTotalRightAnswers(
            parseInt(data.userData.totalRightAnswers ?? '0')
          )
          fetchFlags({
            hash: hash ?? process.env.REACT_APP_TEST_HASH ?? '',
            telegramId:
              user?.id.toString() ?? process.env.REACT_APP_TEST_ID ?? '',
          }).then((data) => {
            setFlags(data)
          })
        }
      })
    }
  }, [userData])

  return (
    <TelegramProvider>
      <div className="min-h-screen bg-black text-white font-press-start m-0">
        <div className="flex flex-col items-center justify-center h-full">
          {view ? (
            renderView(
              view,
              userData,
              refetchUserData,
              currentTotalRightAnswers,
              flags,
              refetchFlags
            )
          ) : (
            <SplashScreenView />
          )}
          <div className="flex flex-col items-center justify-center h-full">
            <ControlBar
              onReferralClick={() => setView(View.Referral)}
              onPlayClick={() => setView(View.Play)}
              onStatsClick={() => setView(View.Stats)}
              onFlagsClick={() => setView(View.Flags)}
            />
          </div>
        </div>
      </div>
    </TelegramProvider>
  )
}

export default App
