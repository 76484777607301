import { useEffect, useCallback } from 'react'
import confetti from 'canvas-confetti'
import { ConfettiProps } from '@/types'

export function Confetti({
  duration = 3000,
  particleCount = 100,
  spread = 70,
  startVelocity = 30,
  colors = [
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#00FF00',
    '#0000FF',
    '#4B0082',
    '#8F00FF',
  ],
  onComplete,
}: ConfettiProps) {
  const fireConfetti = useCallback(() => {
    const end = Date.now() + duration

    const interval = setInterval(() => {
      if (Date.now() > end) {
        clearInterval(interval)
        onComplete?.()
        return
      }

      const particleOptions: confetti.Options = {
        particleCount: Math.floor(particleCount / 10),
        startVelocity,
        spread,
        ticks: 200,
        origin: {
          x: Math.random(),
          y: 1.1, // Start from just below the bottom
        },
        colors,
        gravity: 1.2,
        scalar: 0.7,
        drift: -0.1,
      }

      confetti(particleOptions)
    }, 50)

    return () => clearInterval(interval)
  }, [duration, particleCount, spread, startVelocity, colors, onComplete])

  useEffect(() => {
    fireConfetti()
  }, [fireConfetti])

  return null
}
