import { FlagViewProps } from '@/types/FlagViewTypes'
import HeaderBar from '@/components/HeaderBar'
import { useEffect, useState } from 'react'
import { FlagsResponse } from '@/types/APITypes'
import { calculateFlagValues, FlagTypes, renderTab } from './functions'
import { Badge } from '@/components/Badge'

export const defaultFlags: FlagViewProps = {
  inreviewFlags: 0,
  acceptedFlags: 0,
  rejectedFlags: 0,
  rewards: 0,
  penalties: 0,
  rank: null,
}

export default function FlagsView({
  currentScore,
  dailyStreak,
  currentLevel,
  flags,
  refetchFlags,
}: {
  currentScore: number
  dailyStreak: number
  currentLevel: number
  flags: FlagsResponse[] | null | undefined
  refetchFlags: () => void
}) {
  const [allFlags, setAllFlags] = useState<FlagsResponse | null>(null)
  const [currentTab, setCurrentTab] = useState<FlagTypes>(FlagTypes.HOME)
  const calculatedFlags = flags ? calculateFlagValues(flags) : null

  useEffect(() => {
    refetchFlags()
  }, [])

  return (
    <div className="fixed inset-0 flex flex-col">
      {/* Header */}
      <HeaderBar
        currentScore={currentScore}
        dailyStreak={dailyStreak}
        currentLevel={currentLevel}
      />
      <div>
        <h2 className="text-yellow-500 text-2xl my-6 font-press-start text-center">
          Flags
        </h2>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto p-4">
        <div className="flex justify-center gap-4 mb-4">
          <Badge
            variant={currentTab === FlagTypes.HOME ? 'success' : null}
            onClick={() => setCurrentTab(FlagTypes.HOME)}
          >
            Home
          </Badge>
          <Badge
            variant={currentTab === FlagTypes.IN_REVIEW ? 'success' : null}
            onClick={() => setCurrentTab(FlagTypes.IN_REVIEW)}
          >
            In Review
          </Badge>
          <Badge
            variant={currentTab === FlagTypes.ACCEPTED ? 'success' : null}
            onClick={() => setCurrentTab(FlagTypes.ACCEPTED)}
          >
            Accepted
          </Badge>
          <Badge
            variant={currentTab === FlagTypes.REJECTED ? 'success' : null}
            onClick={() => setCurrentTab(FlagTypes.REJECTED)}
          >
            Rejected
          </Badge>
        </div>
        <div className="max-w-lg mx-auto space-y-6 text-center">
          {renderTab({
            tab: currentTab,
            props: {
              rank: allFlags?.rank ?? null,
              inreviewFlags: calculatedFlags?.inreviewFlags ?? 0,
              acceptedFlags: calculatedFlags?.acceptedFlags ?? 0,
              rejectedFlags: calculatedFlags?.rejectedFlags ?? 0,
              rewards: calculatedFlags?.rewards ?? 0,
              penalties: calculatedFlags?.penalties ?? 0,
            },
            flags,
          })}
        </div>
      </div>
    </div>
  )
}
