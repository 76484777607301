// Badge component

import * as React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { BadgeProps } from '@/types'

const badgeVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'border',
    'px-[9.5px]',
    'py-[1.5px]',
    'font-semibold',
    'transition-colors',
    'gap-1',
    '[&>*]:p-[1px]',
  ].join(' '),
  {
    variants: {
      variant: {
        default: ['border-blue-500', 'text-white', 'rounded-full'].join(' '),
        secondary: ['border-gray-200', 'text-gray-200', 'rounded-full'].join(
          ' '
        ),
        success: ['border-green-500', 'text-white', 'rounded-full'].join(' '),
        destructive: ['border-red-500', 'text-white', 'rounded-full'].join(' '),
        info: ['border-blue-100', 'text-blue-100', 'rounded-full'].join(' '),
        multiplier: [
          'border-yellow-400',
          'bg-yellow-400',
          'text-red-500',
          'font-bold',
          'text-sm',
          'rounded-none',
        ].join(' '),
      },
      rotate: {
        true: '[transform:rotate(-15deg)]',
        false: '',
      },
      size: {
        default: 'text-[10px]',
        sm: 'text-[8px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      rotate: false,
      size: 'default',
    },
  }
)

const icons = {
  info: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="information"
    >
      &#8505;
    </span>
  ),
  trophy: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="trophy"
    >
      🏆
    </span>
  ),
  testTube: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="test tube"
    >
      🧪
    </span>
  ),
  smile: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="smiling face"
    >
      😊
    </span>
  ),
  star: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="star"
    >
      ⭐
    </span>
  ),
  fire: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="fire"
    >
      🔥
    </span>
  ),
  rocket: (
    <span
      className="inline-flex items-center justify-center w-3 h-3"
      role="img"
      aria-label="rocket"
    >
      🚀
    </span>
  ),
} as const

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (
    { className, variant, showIcon, onInfoClick, rotate, icon, ...props },
    ref
  ) => {
    const handleInfoClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      onInfoClick?.(e)
    }

    const IconComponent = icon ? icons[icon] : null
    const InfoIcon = showIcon && variant === 'info' ? icons.info : null

    return (
      <div
        ref={ref}
        className={cn(badgeVariants({ variant, rotate, className }))}
        {...props}
      >
        {InfoIcon && (
          <div
            className="cursor-pointer hover:opacity-80"
            onClick={handleInfoClick}
            role="button"
            aria-label="More information"
          >
            {InfoIcon}
          </div>
        )}
        {props.children}
        {IconComponent}
      </div>
    )
  }
)

Badge.displayName = 'Badge'

export { Badge, badgeVariants, icons as BadgeIcons }
