import React, { ReactNode } from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { StatBlockProps, SplitStatBlockProps } from '@/types'

const statBlockVariants = cva(
  [
    'flex',
    'items-center',
    'justify-center',
    'rounded-lg',
    'p-4',
    'text-center',
    'min-w-[120px]',
    'gap-3',
  ].join(' '),
  {
    variants: {
      variant: {
        default: ['flex-col', 'border-2', 'border-orange-500'].join(' '),
        split: [
          'border',
          'border-gray-200',
          'flex-row',
          'divide-x',
          'divide-gray-200',
          'w-full',
          'justify-between',
        ].join(' '),
      },
      size: {
        default: 'min-h-[100px]',
        sm: 'min-h-[80px]',
        lg: 'min-h-[120px]',
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'default',
    },
  }
)

const primaryTextVariants = cva(['font-bold', 'uppercase'].join(' '), {
  variants: {
    size: {
      default: 'text-2xl',
      sm: 'text-xl',
      lg: 'text-3xl',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

const secondaryTextVariants = cva(['text-gray-500', 'uppercase'].join(' '), {
  variants: {
    size: {
      default: 'text-xs',
      sm: 'text-[10px]',
      lg: 'text-sm',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

interface StatColumnProps {
  primary: string | number
  secondary: string
  size?: 'sm' | 'default' | 'lg'
  icon?: ReactNode
}

const StatColumn = ({ primary, secondary, size, icon }: StatColumnProps) => (
  <div className="flex flex-col justify-center items-center flex-1 px-4 min-w-0 w-1/2">
    <div
      className={primaryTextVariants({ size })}
      style={{ minWidth: '0', width: '100%' }}
    >
      {typeof primary === 'number' ? primary.toLocaleString() : primary}
    </div>
    <div
      className={secondaryTextVariants({ size })}
      style={{ minWidth: '0', width: '100%' }}
    >
      <div className="flex items-center justify-center gap-1">
        {secondary}
        {icon}
      </div>
    </div>
  </div>
)

export function StatBlock(
  props: StatBlockProps & Partial<SplitStatBlockProps>
) {
  const {
    primaryText,
    secondaryText,
    size,
    className,
    variant,
    rightIcon,
    leftIcon,
    leftPrimary,
    leftSecondary,
    rightPrimary,
    rightSecondary,
    ...rest
  } = props

  if (variant === 'split') {
    return (
      <div
        className={cn(statBlockVariants({ size, variant, className }))}
        {...rest}
      >
        <StatColumn
          primary={leftPrimary!}
          secondary={leftSecondary!}
          size={size}
          icon={leftIcon}
        />
        <StatColumn
          primary={rightPrimary!}
          secondary={rightSecondary!}
          size={size}
          icon={rightIcon}
        />
      </div>
    )
  }

  return (
    <div
      className={cn(statBlockVariants({ size, variant, className }))}
      {...rest}
    >
      <div className={primaryTextVariants({ size })}>
        {typeof primaryText === 'number'
          ? primaryText.toLocaleString()
          : primaryText}
      </div>
      <div className={secondaryTextVariants({ size })}>
        <div className="flex items-center justify-center gap-1">
          {secondaryText}
          {rightIcon}
        </div>
      </div>
    </div>
  )
}

export { statBlockVariants }
