import { RankRequest } from '@/types/APITypes'

export async function fetchRank(payload: RankRequest) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/jailing` as string,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}
