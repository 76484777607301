// api stuff

export async function flagAnswer({
  hash,
  telegramId,
}: {
  hash: string
  telegramId: string
}) {
  try {
    const lastAnswerResponse = await fetch(
      `${process.env.REACT_APP_API_ROOT}/getLatestUserAnswers`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash, telegramId, numberOfAnswers: 1 }),
      }
    )
    const lastAnswer = await lastAnswerResponse.json()
    const cid = lastAnswer.value[0].cid
    const payload = {
      hash,
      telegramId,
      cid,
      flag: true,
      flagStatus: 'in_review',
      id: lastAnswer.value[0].id.toString(),
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/updateUserAnswerFlag`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )
    return response
  } catch (error) {
    console.error(error)
    alert(error)
  }
}
