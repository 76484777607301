import { useTelegram } from '@/contexts/TelegramContext'
import { useEffect } from 'react'
import { useState } from 'react'

export function SplashScreenView() {
  const { user, hash } = useTelegram()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [user])
  alert('test')
  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="text-center">
        <div className="text-xs font-bold">Crypto AI</div>
        {isLoading && <div className="text-2xs">Loading...</div>}
        <div>
          <div className="text-2xs">{user?.username}</div>
          <div className="text-2xs">{user?.id}</div>
          <div className="text-2xs">{hash}</div>
        </div>
      </div>
    </div>
  )
}
