import { Coin } from '@/components/Coin'

export const HomeTab = ({
  rank,
  inreviewFlags,
  acceptedFlags,
  rejectedFlags,
  rewards,
  penalties,
}: {
  rank: string | null
  inreviewFlags: number
  acceptedFlags: number
  rejectedFlags: number
  rewards: number
  penalties: number
}) => {
  return (
    <div className="space-y-4 font-press-start text-xs">
      <p>Flagging is essential for improving AI</p>
      <p className="mb-8">
        Earn rewards for accurate flags, but incur penalties for after 3
        incorrect flags.
      </p>
      <div className="flex w-full">
        <p className="text-xs">Your Flags</p>
      </div>
      <div className="border-2 border-orange-500 rounded-lg p-4 mb-4">
        <div className="grid grid-cols-3 gap-4 text-center">
          <div className="font-bold">Status</div>
          <div className="font-bold"># Flags</div>
          <div className="font-bold">Rewards</div>

          <div className="text-xs">In Review</div>
          <div>{inreviewFlags}</div>
          <div>-</div>

          <div className="text-xs">Accepted</div>
          <div>{acceptedFlags}</div>
          <div className="flex items-center justify-center">
            +{rewards} <Coin animate={false} />
          </div>

          <div className="text-xs">Rejected</div>
          <div>{rejectedFlags}</div>
          <div className="flex items-center justify-center">
            -{penalties} <Coin animate={false} />
          </div>
        </div>
      </div>
      {rank && <div className="text-xs mb-4">Your Flagging Rank: {rank}</div>}
      <div className="text-xs">{`Keep flagging to do the Lord's work!`}</div>
    </div>
  )
}
