import { affirmations, answerSwaps, warningMessages } from '@/lib/consts'
import { cn, storeResponseData } from '@/lib/utils'
import { ImageResult } from '@/types/APITypes'
import { SWAP_ANSWER_THRESHOLD } from './PlayView'
import { Badge } from '@/components/Badge'

export function getRandomPhrase(
  type: 'affirmation' | 'warning' = 'affirmation'
) {
  if (type === 'affirmation') {
    return affirmations[Math.floor(Math.random() * affirmations.length)]
  } else {
    return warningMessages[Math.floor(Math.random() * warningMessages.length)]
  }
}
export function checkAnswer(
  answer: string,
  answerSwap: string | null,
  correctAnswer: string,
  subject: string,
  setShowConfetti: (show: boolean) => void
) {
  // random chance to show confetti
  function randomConfetti() {
    const random = Math.random()
    if (random < 0.1) {
      setShowConfetti(true)
    }
  }
  if (!answerSwap) {
    if (answer === 'yes') {
      randomConfetti()
      return correctAnswer === subject
    } else {
      // answer was no, so we want to check if the subject is not the correct answer
      randomConfetti()
      return correctAnswer !== subject
    }
  } else {
    // we swapped
    if (answer === 'yes') {
      randomConfetti()
      return answerSwap === correctAnswer
    } else {
      randomConfetti()
      return answerSwap !== correctAnswer
    }
  }
}
export function storeAnswer({
  image,
  answer,
  answerSwap,
  correctAnswer,
  playTime,
}: {
  image: ImageResult
  answer: string
  answerSwap: string | null
  correctAnswer: string | null
  playTime: number
}) {
  storeResponseData({
    imageData: image,
    answer: answer,
    answerSwap: answerSwap,
    correctAnswer: correctAnswer,
    playTime: playTime,
  })
}
export function applyRandomSwap() {
  const random = Math.random()
  if (random < SWAP_ANSWER_THRESHOLD) {
    return answerSwaps[Math.floor(Math.random() * answerSwaps.length)]
  }
  return null
}

export function renderImageSection(
  answerSwap: string | null,
  image: ImageResult,
  multiplier: string | undefined,
  isImageLoading: boolean,
  onImageLoad: () => void
) {
  return (
    <div className="flex flex-col items-center relative">
      <div className="text-center text-question mb-2">
        {isImageLoading
          ? 'Loading...'
          : `Is this a ` +
            (answerSwap ? answerSwap : (image.subject ?? answerSwap)) +
            '?'}
      </div>
      <div className="relative">
        <div className="absolute -top-2 left-1/2 -translate-x-1/2 w-[98%] h-[98%] bg-gray-950" />
        <div className="absolute -top-1 left-1/2 -translate-x-1/2 w-[99%] h-[99%] bg-gray-900" />

        {/* Container with fixed dimensions */}
        <div className="relative w-[300px] h-[385px] rounded-lg overflow-hidden">
          {/* Black background */}
          <div className={cn('absolute inset-0', 'bg-black')} />

          {/* Loading placeholder */}
          {isImageLoading && (
            <div className="absolute inset-0 animate-pulse bg-gray-800" />
          )}

          {/* Image container with object-fit */}
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src={image.url}
              alt={image.subject}
              className={cn(
                'max-w-full max-h-full object-contain',
                'transition-opacity duration-300',
                isImageLoading ? 'opacity-0' : 'opacity-100'
              )}
              onLoad={onImageLoad}
            />
          </div>
        </div>

        <div className="absolute -top-3 -right-16">
          <Badge variant="multiplier" rotate={true}>
            {multiplier ? multiplier : '1'}x
          </Badge>
        </div>
      </div>
    </div>
  )
}
