import { useState, useEffect, useRef } from 'react'
import { cn } from '@/lib/utils'
import { NotificationMessageProps } from '@/types/NotificationMessageTypes'
import { Progress } from '@/components/ProgressBar'
import { Button } from '@/components/Button'

// Helper function to detect if a string is only emojis
const isEmojiOnly = (str: string) => {
  const emojiRegex =
    /^[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27BF\u2700-\u27BF]+$/
  return emojiRegex.test(str.trim())
}

export default function NotificationMessage({
  message,
  duration = 3000,
  showCloseButton = false,
  onDismiss,
  variant = 'default',
  noTimer = false,
}: NotificationMessageProps) {
  const [show, setShow] = useState(true)
  const [progress, setProgress] = useState(100)
  const startTimeRef = useRef<number>()
  const animationFrameRef = useRef<number>()

  // Check if message is emoji-only
  const isEmoji = isEmojiOnly(message)

  const dismiss = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }
    setShow(false)
    setTimeout(() => {
      onDismiss?.()
    }, 300)
  }

  useEffect(() => {
    startTimeRef.current = Date.now()

    const animate = () => {
      const elapsed = Date.now() - startTimeRef.current!
      const remaining = Math.max(0, duration - elapsed)
      const newProgress = (remaining / duration) * 100

      setProgress(newProgress)

      if (newProgress > 0) {
        animationFrameRef.current = requestAnimationFrame(animate)
      } else {
        dismiss()
      }
    }

    animationFrameRef.current = requestAnimationFrame(animate)

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [duration])

  const getVariantStyles = () => {
    switch (variant) {
      case 'success':
        return 'bg-green-900 opacity-75'
      case 'warning':
        return 'bg-red-900 opacity-75'
      default:
        return 'bg-green-900 opacity-75'
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex justify-center animate-slide-in">
      <div className={cn('m-4 p-4 rounded-lg shadow-lg', getVariantStyles())}>
        {message}
      </div>
    </div>
  )
}
