import React, { useEffect, useState } from 'react'
import { Confetti } from './Confetti'
import { Coin } from './Coin'
import { ProgressSectionProps } from '@/types'

export function ProgressSection({
  progress,
  showConfetti = true,
  nextReward = 100,
  currentLevel = 1,
}: ProgressSectionProps) {
  const [celebrate, setCelebrate] = useState(false)

  useEffect(() => {
    if (progress >= 100 && showConfetti) {
      setCelebrate(true)
    } else {
      setCelebrate(false)
    }
  }, [progress, showConfetti])

  return (
    <div className="relative w-full max-w-md">
      <div className="mb-1 flex justify-between text-xs text-gray-500">
        <div>Lvl. {currentLevel}</div>
        <div>Lvl. {currentLevel + 1}</div>
      </div>
      <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="h-full bg-blue-500 transition-all duration-500 ease-out"
          style={{ width: `${Math.min(100, Math.max(0, progress))}%` }}
        />
      </div>
      <div className="mt-2 flex justify-between items-center text-sm">
        {/* <div className="text-gray-600">Progress: {Math.round(progress)}%</div> */}
        <div className="flex items-center gap-1 text-gray-600 ml-auto">
          Next Reward: <span className="text-question">{nextReward}</span>{' '}
          <Coin className="w-4 h-4" animate={false} />
        </div>
      </div>
      {celebrate && <Confetti />}
    </div>
  )
}
