import React from 'react'
import { Button } from './Button'
import { cn } from '@/lib/utils'

export interface ControlBarProps extends React.HTMLAttributes<HTMLDivElement> {
  onReferralClick?: () => void
  onPlayClick?: () => void
  onStatsClick?: () => void
  onFlagsClick?: () => void
  disabled?: boolean
  buttonRefs?: {
    referral?: React.RefObject<HTMLButtonElement>
    play?: React.RefObject<HTMLButtonElement>
    stats?: React.RefObject<HTMLButtonElement>
    flags?: React.RefObject<HTMLButtonElement>
  }
}

export function ControlBar({
  className,
  onReferralClick,
  onPlayClick,
  onStatsClick,
  onFlagsClick,
  disabled,
  buttonRefs,
  ...props
}: ControlBarProps) {
  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 right-0 z-50',
        'flex items-center justify-between bg-gray-900 px-4 py-2 rounded-t-xl gap-2',
        className
      )}
      {...props}
    >
      <Button
        variant="ghost"
        size="icon"
        onClick={onReferralClick}
        disabled={disabled}
        icon={<span className="text-2xl">🫂</span>}
        aria-label="Referral"
        ref={buttonRefs?.referral}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={onPlayClick}
        disabled={disabled}
        icon={<span className="text-2xl">🎮️</span>}
        aria-label="Play"
        ref={buttonRefs?.play}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={onStatsClick}
        disabled={disabled}
        icon={<span className="text-2xl">📈</span>}
        aria-label="Stats"
        ref={buttonRefs?.stats}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={onFlagsClick}
        disabled={disabled}
        icon={<span className="text-2xl">🚩</span>}
        aria-label="Flags"
        ref={buttonRefs?.flags}
      />
    </div>
  )
}
