import { Card, CardContent } from '@/components/Card'
import { Button } from '@/components/Button'

interface BetaViewProps {
  onClose?: () => void
  feedbackLink?: string
}

export default function BetaView({ onClose, feedbackLink }: BetaViewProps) {
  return (
    <div className="fixed inset-0 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg text-white shadow-xl overflow-hidden">
        <CardContent className="p-8 space-y-6 text-center">
          <h2 className="text-3xl md:text-4xl font-bold tracking-wider flex items-center justify-center gap-2">
            Welcome to the Beta! 🚀
          </h2>

          <p className="text-2xl md:text-3xl tracking-wider">
            You might spot some bugs!
          </p>

          <p className="text-xl md:text-2xl tracking-wider">
            Help us improve by sharing your feedback{' '}
            <a
              href={feedbackLink}
              className="text-white underline underline-offset-4 hover:text-white/80 transition-colors"
            >
              here
            </a>
          </p>

          <Button
            variant="retro"
            className="font-press-start"
            onClick={onClose}
          >
            Close
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}
