export const warningMessages = [
  'Incorrect...',
  'Not quite...',
  'Nope...',
  'Try again...',
  'Not this time...',
  'Not quite right...',
  'Nah dawg...',
  'Not even close...',
  'r u kidding me?',
  'Nah fam...',
  'Wrong, buddy...',
  'That was embarrassing...',
  'You can do better...',
  'Try harder next time...',
  'Uuuuuhhh...',
  '1) what',
  'Really??',
  'Lol...',
  'Uh that was lame...',
  'I mean...no.',
  'ngmi',
  'Bruh...',
  'Lmao...',
  'Listen...that was wrong...',
  'wat',
  '💀', // skull
  '😵', // dizzy face
  '😱', // face screaming in fear
  '🤦', // facepalm
  '❌', // cross mark
  '⛔', // no entry
  '🚫', // prohibited
  '🚨', // rotating light
]

export const affirmations = [
  'Right Answer!',
  'Noiiiice!',
  'Bravo!',
  'Correct!',
  'Keep going!',
  'Happy 🤖',
  'GM!',
  'GW!',
  'Happy 🤖',
  "That's it 🎉",
  '🐐',
  '🎸',
  '🔥',
  '🎆',
  '⚡',
  'AI legend!',
  'AI superhero!',
  'AI whisperer!',
  'GOAT',
  'MVP of the day!',
  'AI gods are pleased!',
  "You're on 🔥",
  'Epic!',
  'AI genius!',
  'Making AI good',
  'Unstoppable!',
  'Big brain!',
  'Next-level!',
  'Boss mode!',
  'On fire!',
  'Genius vibes!',
  'Epic win!',
  'Total pro!',
  'Crushing it!',
  'Pure gold!',
  'Rockstar! 🤘',
  'Top player!',
  'Killing it!',
  'Master level!',
  'Power move!',
  'Big flex!',
  'So pro!',
  'Elite status!',
  'Magic touch!',
  'All-star!',
  'Too good!',
  'A+ work!',
  'Chief of Clicks!',
  'CEO of AI!',
]

export const answerSwaps = [
  'cat',
  'dinosaur',
  'dog',
  'rabbit',
  'sloth',
  'squirrel',
  'alien',
  'ant',
  'ape',
  'elephant',
  'octopus',
  'owl',
  'unicorn',
  'witch',
  'cyborg',
  'dwarf',
  'dragon',
  'elf',
  // 'pixelated',
  'vampire',
  'angel',
  'centaur',
  'ghost',
  'merman',
  'mermaid',
  'zombie',
  'anime',
  'cartoon',
  'wizard',
]

export const times = {
  half_second: 500,
  '1_second': 1000,
  '2_seconds': 2000,
  '5_seconds': 5000,
  '10_seconds': 10000,
  '20_seconds': 20000,
  '30_seconds': 30000,
  '1_minute': 60000,
  '2_minutes': 120000,
  '3_minutes': 180000,
  '4_minutes': 240000,
  '5_minutes': 300000,
}
