import { deriveAnswerFromResult } from '@/lib/utils'
import { FlagsResponse } from '@/types/APITypes'
import { FlagTypes, trimCid } from './functions'

export const ListTab = (props: {
  tab: FlagTypes
  flags: FlagsResponse[] | null | undefined
}) => {
  const filteredFlags = props.flags?.filter(
    (flag) => flag.flag_status === props.tab
  )

  return (
    <div>
      <div>{props.tab.toUpperCase().replace('_', ' ')} Flags</div>
      <div className="overflow-y-auto max-h-[calc(100vh-300px)] px-4 pb-20">
        {filteredFlags?.length ? (
          filteredFlags.map((flag) => (
            <div
              key={flag.cid}
              className="flex items-center gap-4 text-xs border my-2 rounded-2xs p-2"
            >
              <img
                src={`${process.env.REACT_APP_IPFS_ROOT}/${flag.cid}`}
                alt="flag"
                className="w-16 h-16"
              />
              <div className="flex flex-col space-y-2 text-left text-2xs">
                <div>cid/{trimCid(flag.cid)}</div>
                <div>{new Date(flag.datetime).toLocaleDateString('en-US')}</div>
              </div>
              <div>
                <div className="text-2xs">Your Answer</div>
                <div className="text-2xs">
                  {deriveAnswerFromResult(
                    parseInt(flag.rightanswer),
                    flag.displayed_category,
                    flag.real_category
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-500">
            No {props.tab.toLowerCase().replace('_', ' ')} flags to display
          </div>
        )}
      </div>
    </div>
  )
}
