import { FlagsResponse } from '@/types/APITypes'

export async function fetchFlags({
  hash,
  telegramId,
}: {
  hash: string
  telegramId: string
}): Promise<FlagsResponse[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/getUserFlags`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash, telegramId }),
      }
    )
    const data = await response.json()
    return data.value
  } catch (error) {
    console.error(error)
    return []
  }
}
