interface CoinProps {
  className?: string
  animate?: boolean
}

export function Coin({ className = 'w-5 h-5', animate = true }: CoinProps) {
  return (
    <span className="perspective-[1000px]" style={{ perspective: '1000px' }}>
      <img
        src="/coin.svg"
        alt="coin"
        className={`${className} ${animate ? 'animate-[rotateY_2s_linear_infinite] preserve-3d' : ''}`}
      />
    </span>
  )
}
